@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-right {
  text-align: right !important;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f0f0f0
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f5f5f5;
  border-radius: 50%
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #d2d2d2
}

.k-input-values {
  display: flex;
}

.editor ol {
  list-style: decimal;
}

.editor ul {
  list-style: disc;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn
}

.animated {
  opacity: 1;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0)
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0)
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-delay: 1000ms;
}

/* since nested groupes are not supported we have to use 
     regular css for the nested dropdowns 
  */
li>ul {
  transform: translatex(100%) scale(0)
}

li:hover>ul {
  transform: translatex(101%) scale(1)
}

li>button svg {
  transform: rotate(-90deg)
}

li:hover>button svg {
  transform: rotate(-270deg)
}

/* Below styles fake what can be achieved with the tailwind config
     you need to add the group-hover variant to scale and define your custom
     min width style.
  	 See https://codesandbox.io/s/tailwindcss-multilevel-dropdown-y91j7?file=/index.html
  	 for implementation with config file
  */
.group:hover .group-hover\:scale-100 {
  transform: scale(1)
}

.group:hover .group-hover\:-rotate-180 {
  transform: rotate(180deg)
}

.scale-0 {
  transform: scale(0)
}

.min-w-32 {
  min-width: 8rem
}